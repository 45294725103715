<template>
    <div class="offer-additional-pictures" :class="size">
        <nuxt-link :to="picture.link" class="item" v-for="picture of displayImages" :key="picture.picture">
            <common-image :image="picture.picture" size="offer-sub"></common-image>
        </nuxt-link>
        <nuxt-link class="additional-count item" :to="item.link" v-if="additionalSize">
            +{{additionalSize}}
        </nuxt-link>
    </div>
</template>

<script>
export default {
    components: {
        commonImage: require('~/components/common/image/').default,
    },
    props: ['group', 'size', 'item'],
    computed: {
        targetSize() {
            return 6;
        },
        displayImages() {
            if (this.group.pictures.length >= this.targetSize) {
                return this.group.pictures.slice(0, this.targetSize - 1);
            }

            return this.group.pictures;
        },
        additionalSize() {
            if (this.group.totalCount >= this.targetSize) {
                return Math.max(this.group.totalCount - (this.targetSize - 1), 0);
            }

            return 0;
        }
    }
}
</script>
