<template>
    <div class="props-container">
        <!-- <div class="local-props" v-if="regularProps.length">
            <div class="prop" v-for="prop of regularProps" :key="prop.name">
                <div class="title">{{prop.name}}:</div>
                <div class="value">{{prop.value}}</div>
            </div>
        </div> -->
        <div class="size-props" v-if="sizeProps.length">
            <template v-for="(prop, i) of sizeProps">
                <div class="delimeter" v-if="i" :key="i">&times;</div>
                <div :key="prop.name" class="size">
                    <div class="title">{{prop.name}}</div>
                    <div class="value">{{prop.value}}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ['props'],
    computed: {
        sizeProps() {
            return this.props.filter((el) => el.size);
        },
        regularProps() {
            return this.props.filter((el) => !el.size);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/colors.scss';

.props-container {
    margin-bottom: 10px;
}

.delimeter {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

.size-props {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.title {
    color: $color-text-lighten;
    margin-bottom: 5px;
}

.value {
    color: black;
    font-weight: 500;
    text-align: center;
}

.size:first-child .value {
    text-align: left !important;
}
.size:last-child .value {
    text-align: right;
}

.local-props {

    &>* {
        display: flex;
        justify-content: space-between;
    }

    margin-bottom: 5px;
}

</style>