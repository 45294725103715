<template>
    <section class="base-container">
        <header>Цена указана за пример комплектации:</header>
        <div class="item" v-for="item of displayBase" :key="item.id">
            <span>{{item.name}}</span>
            <span class="amount">{{item.amount}} шт.</span>
        </div>
        <div v-if="base.length > 2 && !showMore" class="show-more" @click="showMore = true">Ещё</div>
    </section>
</template>

<script>
export default {
    props: ['base'],
    data: () => ({showMore: false}),
    computed: {
        displayBase() {
            return this.showMore ? this.base : this.base.slice(0, 2);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/colors.scss';

.props-container {
    margin-bottom: 10px;
}

.show-more {
    cursor: pointer;
    text-align: right;
    color: $color-text-lighten;
    text-decoration: underline;
}

header {
    color: $color-text;
    margin-bottom: 4px;
}

.item {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-end;
    color: $color-text-lighten;
    margin-bottom: 7px;
}

.amount {
    white-space: nowrap;
}

</style>