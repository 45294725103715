<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        class="favorite-icon"
        :class="{loading: loading, checked: checked}"
        @click.capture.prevent="toggleFavorite"
        viewBox="0 0 30.07 26.44">
        <g>
            <path class="background" d="M9.5,1l.82.08a8.27,8.27,0,0,1,4.55,2.11l.15.14.55-.47a8,8,0,0,1,5.93-1.8,8.26,8.26,0,0,1,7.33,6.28,8.05,8.05,0,0,1-.13,4.38,12.13,12.13,0,0,1-.62,1.75,15.42,15.42,0,0,1-2.17,3.2,35.74,35.74,0,0,1-4.43,4.27,63.17,63.17,0,0,1-5.87,4.31.94.94,0,0,1-1.13,0,60.83,60.83,0,0,1-7-5.32A31.11,31.11,0,0,1,3.8,16.22a12.65,12.65,0,0,1-2.12-3.5A9.86,9.86,0,0,1,1,9.93l0-.12v-1c0-.1,0-.19,0-.29A8.3,8.3,0,0,1,8.2,1.09c.3,0,.62-.06.93-.09Z" transform="translate(0 0)"/>
        </g>
    </svg>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props: ['productId'],
    data: () => ({loading: false, checked: null}),
    mounted() {
        this.loadFavorite();
    },
    computed: mapState(['user']),
    methods: {
        async toggleFavorite() {
            if (this.loading) {
                return;
            }

            const currentValue = this.value;

            this.loading = true;

            try {
                this.checked = !this.checked;

                await this.$store.dispatch('favorites/ToggleProductFavorite', {
                    id: this.productId,
                    value: this.checked,
                });

            } catch {
                this.checked = currentValue;
            } finally {
                this.loading = false;
            }
        },
        loadFavorite() {
            this.$store.dispatch('favorites/CheckProductFavorite', {
                id: this.productId,
                callback: (checked) => {
                    this.checked = checked;
                },
            })
        }
    },
    watch: {
        user() {
            this.loadFavorite();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/colors';

.favorite-icon {
    cursor: pointer;
    height: 32px;

    transition: filter .1s ease;

    .background {
        transition: fill .1s ease, stroke .1s ease;
        fill: white;
        stroke: $color-red;
        stroke-miterlimit: 10;
        stroke-width: 2px;
    }

    &.checked .background {
        fill: $color-red;
    }

    &:hover {
        filter: drop-shadow(0 0 2px rgba($color-red, .2));
    }
}
</style>