import { Component, Vue, Prop as prop} from 'nuxt-property-decorator';

@Component({
    components: {
        simplePicture: require('../image/').default,
    },
})
export default class SimplePictureComponent extends Vue {
    @prop({required: true})
    public images: string[];

    @prop({type: String, required: true})
    public size: string;

    usePosition = false;
    position = 0;

    mounted() {
        if (this.images.length > 1) {
            (this.$el as HTMLElement).addEventListener('mouseenter', () => {
                this.setupListeners();
            }, {passive: true, once: true});
        }
    }

    private setupListeners() {
        
        const rect = this.$el.getBoundingClientRect();

        (this.$el as HTMLElement).addEventListener('mouseleave', () => {
            this.usePosition = false;
        }, {passive: true});

        

        (this.$el as HTMLElement).addEventListener('mousemove', (event: any) => {
            this.position = Math.trunc(event.layerX / rect.width * this.images.length);
            this.usePosition = true;
        }, {passive: true});
    }

    get currentIdx() {

        if (this.usePosition) {
            return this.position;
        }

        return 0;
    }
}