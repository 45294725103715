import { Component, Vue, Prop as prop, Prop} from 'nuxt-property-decorator';
import { mapState } from 'vuex';

@Component({
    components: {
        btn: require('~/components/form/button/').default,
    },
    computed: mapState('cart', ['offers'])
})
export default class OfferComponent extends Vue {

    @Prop() item: Offer;
    private buying: boolean = false;
    
    offers: Map<number, any>;

    private async buy() {

        try {
            this.buying = true;

            await this.$store.dispatch('cart/Add', {
                offerId: this.item.id,
            });

            this.trackBuy();

        } finally {
            this.buying = false;
        }
    }

    private trackBuy() {
        const counter = (window as any).yaCounter;

        if (!counter) {
            return;
        }

        counter.reachGoal("BUY");
    }

    get inCart() {

        if (this.item.base?.length) {
            return !this.item.base.some((el) => !this.offers.has(el.id));
        }

        return this.offers.has(this.item.id);
    }
}