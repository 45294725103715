import { Component, Vue, Prop as prop} from 'nuxt-property-decorator';
import LazyHydrate from 'vue-lazy-hydration';

@Component({
    components: {
        LazyHydrate,
        // rating: require('~/components/common/rating/').default,
        customPicture: require('~/components/common/switching-picture/').default,
        customImage: require('~/components/common/image/').default,
        btn: require('~/components/form/button/').default,
        favoriteBtn: require('~/components/pages/product/favorite-btn').default,
        // materialInfo: require('~/components/catalog/offer-material-info').default,
        propsInfo: require('~/components/catalog/offer-props').default,
        baseInfo: require('~/components/catalog/offer-base').default,
        buyBtn: require('~/components/catalog/offer-buy-btn').default,
        additionalPictures: require('~/components/catalog/offer-additional-pictures').default,
    },
})
export default class OfferComponent extends Vue {

    @prop()
    private item: Offer;

    @prop({type: Boolean, default: false})
    private showWithoutPrice: boolean;

    private tracked = false;
    
    public isFavorite: boolean = false;


    private trackLink() {
        const counter = (window as any).yaCounter;

        if (!counter || this.tracked) {
            return;
        }

        this.tracked = true;

        if (this.item.new) {
            if (!!this.item.price.oldPrice) {
                counter.reachGoal("CARDNEWSALE");
            } else {
                counter.reachGoal("CARDNEW");
            }
        } else {
            if (!!this.item.price.oldPrice) {
                if (this.item.limited) {
                    counter.reachGoal("SALEOGR");
                } else {
                    counter.reachGoal("SALE");
                }
            } else {
                counter.reachGoal("CARD");
            }
        }
    }

    get discountPercent() {
        if (!this.item?.price?.oldPrice) {
            return null;
        }

        const price = this.item.price;

        return Math.round((price.oldPrice - price.price) / price.oldPrice * 100);
    }
}
